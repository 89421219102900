<template>
  <section>
    <div class="bg-white px-2 py-4 mt-1 rounded-md">
      <div class="flex items-end">
        <div class="w-6/12">
          <TabMenu class="w-min" :model="items" />
        </div>
        <div class="flex w-6/12 gap-2" v-if="$route.name === 'ajustes.permisos.listado-permisos'">
          <AutoComplete
            v-model="permisoBuscado"
            :pt="{ root: { style: 'height: 2.3rem' } }"
            size="small"
            placeholder="Buscar"
            @complete="buscarPermisos(permisoBuscado)"
            :suggestions="listadoPermisos"
            @item-select="setPermisoSeleccionado()"
            @input="setPermisoSeleccionado()"
            optionLabel="action"
            class="text-xs w-full"
            inputClass="w-full"
            panelClass=""
          >
            <template #option="slotProps">
              <div class="flex align-options-center">
                <div class="text-sm pr-1">
                  <div class="flex flex-wrap gap-2"><p style="text-wrap: balance" class="">Nombre: {{ slotProps.option.name }}</p></div>
                  <div class="flex flex-wrap gap-2"><p style="text-wrap: balance">Action: {{ slotProps.option.action }}</p></div>
                </div>
              </div>
            </template>
          </AutoComplete>
          <Button v-if="permisoBuscado" @click="limpiarFiltro" size="small" style="height: fit-content;" severity="danger" icon="pi pi-trash"  />
          <Button @click="displayModalCrearPermiso = true" size="small" class="w-3/12" style="height: fit-content;" icon="pi pi-key" label="Crear permiso" />
        </div>
        <div class="flex w-6/12 gap-2" v-if="$route.name === 'ajustes.permisos.listado-menus'">
          <AutoComplete
            v-model="menuBuscado"
            :pt="{ root: { style: 'height: 2.3rem' } }"
            size="small"
            placeholder="Buscar"
            @complete="buscarMenus(menuBuscado)"
            :suggestions="listadoMenus"
            @item-select="setMenuSeleccionado()"
            @input="setMenuSeleccionado()"
            optionLabel="action"
            class="text-xs w-full"
            inputClass="w-full"
            panelClass=""
          >
            <template #option="slotProps">
              <div class="flex align-options-center">
                <div class="text-sm pr-1">
                  <div class="flex flex-wrap gap-2"><p style="text-wrap: balance" class="">Nombre: {{ slotProps.option.name }}</p></div>
                  <div class="flex flex-wrap gap-2"><p style="text-wrap: balance">Action: {{ slotProps.option.action }}</p></div>
                </div>
              </div>
            </template>
          </AutoComplete>
          <Button v-if="permisoBuscado" @click="limpiarFiltro" size="small" style="height: fit-content;" severity="danger" icon="pi pi-trash"  />
          <Button size="small" @click="displayModalCrearMenu = true" style="height: fit-content;" class="w-3/12" icon="pi pi-home" label="Crear menú" />
        </div>
      </div>
      <router-view></router-view>
      <Dialog header="Crear permiso" :closable="false" v-model:visible="displayModalCrearPermiso" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}" :modal="true">
          <div class="w-full my-2">
            <div class="w-full grid grid-cols-1 gap-2">
              <div>
                <label class="w-full text-sm text-gray-600" for="">Editable</label>
                <div class="w-full flex items-center">
                  <InputSwitch class="w-2/10" v-model="dataPermiso.editable" />
                  <span class="italic text-xs text-gray-600 ml-4 w-10/12">Al seleccionar esta opción se creara un premiso de creación edición y este permiso no podra tener mas pesmisos hijos</span>
                </div>
              </div>
              <div>
                <label class="w-full text-sm text-gray-600" for="">Servicio</label>
                <div class="w-full flex items-center">
                  <InputSwitch @change="limpiarPadres()" class="w-2/10" v-model="dataPermiso.servicio" />
                  <span class="italic text-xs text-gray-600 ml-4 w-10/12">Al seleccionar esta opción se creara el menu y el servicio por defecto</span>
                </div>
              </div>
            </div>
            <div class="field w-full" v-if="!dataPermiso.servicio">
              <label class="w-full text-sm text-gray-600" for="name">Permiso padre</label>
              <AutoComplete
                v-model="permisoPadreSeleccionado"
                modelValue="id"
                @item-select="setPermisoPadreSeleccionado"
                :pt="{ root: { style: 'height: 2.3rem' } }"
                size="small"
                @complete="buscarPermisos(permisoPadreSeleccionado)"
                :suggestions="listadoPermisos"
                optionLabel="action"
                class="text-xs w-full"
                inputClass="w-full"
                :forceSelection="true"
              >
                <template #option="slotProps">
                  <div class="flex align-options-center">
                    <div class="text-sm pr-1">
                      <div class="flex flex-wrap gap-2"><p style="text-wrap: balance" class="">Nombre: {{ slotProps.option.name }}</p></div>
                      <div class="flex flex-wrap gap-2"><p style="text-wrap: balance">Action: {{ slotProps.option.action }}</p></div>
                    </div>
                  </div>
                </template>
              </AutoComplete>
            </div>
          </div>
          <div class="w-full my-2">
            <div class="field w-full">
              <label class="w-full text-sm text-gray-600" for="name">Nombre del permiso</label>
              <InputText class="w-full" id="name" v-model="dataPermiso.name" />
            </div>
          </div>
          <div class="w-full my-2">
            <div class="field w-full">
              <label class="w-full text-sm text-gray-600" for="description">Descripción</label>
              <InputText class="w-full" id="description" v-model="dataPermiso.description" />
            </div>
          </div>
          <div class="w-full my-2">
            <div class="field w-full">
              <label class="w-full text-sm text-gray-600" for="name">Nombre del action</label>
              <InputText class="w-full" id="name" v-model="dataPermiso.action" />
            </div>
          </div>
          <template #footer>
              <Button label="Cancelar" icon="pi pi-times" @click="cerrarModalCrearPermiso()" class="p-button-text"/>
              <Button label="Guardar" icon="pi pi-check" @click="crearPermiso()" autofocus />
          </template>
      </Dialog>
      <Dialog header="Crear menú" :closable="false" v-model:visible="displayModalCrearMenu" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}" :modal="true">
        <div class="w-full">
          <div class="w-full block my-2">
            <label class="w-full text-sm text-gray-600" for="servicioSwitch">Servicio</label>
            <div class="w-full">
              <p class="italic text-gray-600 text-xs mb-2">Al activar esta opción se creara un nuevo servicio padre y el permiso por defecto</p>
              <InputSwitch @change="setMenuServicio" id="servicioSwitch" class="" v-model="dataMenu.servicio" />
            </div>
          </div>
          <div class="field w-full my-2">
            <label class="w-full text-sm text-gray-600" for="name">Iconos</label>
            <Dropdown class="w-full rounded-md border-gray-300" v-model="dataMenu.icon" :options="iconos" placeholder="Seleccione" >
              <template #value="slotProps">
                  <div class="country-item country-item-value" v-if="slotProps.value">
                    <div class="flex gap-2 items-center">
                      <i class="pi" :class="'pi-' + slotProps.value"></i>
                      <div>{{slotProps.value}}</div>
                    </div>
                  </div>
                  <div class="country-item country-item-value" v-else>
                    {{slotProps.placeholder}}
                  </div>
              </template>
              <template #option="slotProps">
                  <div class="flex gap-2">
                    <i class="pi" :class="'pi-' + slotProps.option"></i>
                    <div>{{slotProps.option}}</div>
                  </div>
              </template>
            </Dropdown>
            <span v-if="creandoMenus && !dataMenu.icon" class="text-red text-xs italic">Este campo es requerido</span>
          </div>
          <div class="field w-full my-2">
            <label class="w-full text-sm text-gray-600" for="name">Nombre del {{ dataMenu.servicio ? 'servicio' : 'menú' }}</label>
            <InputText @input="setListadoOrdenamiento(dataMenu.name)" type="text" class="w-full" v-model="dataMenu.name" />
            <span v-if="creandoMenus && !dataMenu.name" class="text-red text-xs italic">Este campo es requerido</span>
          </div>
          <div v-if="!dataMenu.servicio" class="field w-full my-2">
            <label class="w-full text-sm text-gray-600" for="name">Menú padre</label>
            <AutoComplete
              v-model="menuPadreSeleccionado"
              :pt="{ root: { style: 'height: 2.3rem' } }"
              size="small"
              @complete="buscarMenus(menuPadreSeleccionado)"
              @item-select="setMenuPadre"
              :suggestions="listadoMenus"
              :forceSelection="true"
              optionLabel="action"
              class="text-xs w-full"
              inputClass="w-full"
              panelClass=""
            >
              <template #option="slotProps">
                <div class="flex align-options-center">
                  <div class="text-sm pr-1">
                    <div class="flex flex-wrap gap-2"><p style="text-wrap: balance" class="">Nombre: {{ slotProps.option.name }}</p></div>
                    <div class="flex flex-wrap gap-2"><p style="text-wrap: balance">Action: {{ slotProps.option.action }}</p></div>
                  </div>
                </div>
              </template>
            </AutoComplete>
            <span v-if="creandoMenus && !menuPadreSeleccionado" class="text-red text-xs italic">Este campo es requerido</span>
          </div>
          <div>
            <label class="w-full text-sm text-gray-600" for="name">Action</label>
            <InputText type="text" class="w-full" v-model="dataMenu.action" />
            <span v-if="creandoMenus && !dataMenu.action" class="text-red text-xs italic">Este campo es requerido</span>
          </div>
          <div v-if="listadoOrdenamientosMenusHijos.length">
            <label class="w-full text-sm text-gray-600" for="name">Ordenamiento</label>
            <div class="w-full h-56 overflow-auto">
              <DataTable :value="listadoOrdenamientosMenusHijos" :rowClass="rowClass" class="p-datatable-sm text-xs" :reorderableColumns="true" @rowReorder="onRowReorder">
                <Column rowReorder headerStyle="width: 3rem" :reorderableColumn="false" />
                <Column v-if="!dataMenu.servicio" field="icon" header="">
                  <template #body="slotProps">
                    <i class="pi" :class="'pi-' + slotProps.data.icon"></i>
                  </template>
                </Column>
                <Column field="name" header=""></Column>
                <Column field="order" header=""></Column>
              </DataTable>
            </div>
          </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="cerrarModalCrearMenu()" class="p-button-text"/>
            <Button label="Guardar" icon="pi pi-check" @click="crearMenu()" autofocus />
        </template>
      </Dialog>
    </div>
  </section>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import { usePermisosStore } from './stores/permisos.store'
import { useServiciosStore } from './stores/servicios.store'
import { useMenusStore } from './stores/menus.store'
import Swal from 'sweetalert2'
export default {
  name: 'permisosHome',
  setup () {
    // Stores
    const permisosStore = usePermisosStore()
    const serviciosStore = useServiciosStore()
    const menusStore = useMenusStore()
    // Computed
    const listadoPermisos = computed(() => permisosStore._permisos_flitrados)
    const listadoMenus = computed(() => menusStore._menus_flitrados)
    const listadoTodosServicios = computed(() => serviciosStore._todos_servicios)
    // Referencias
    const permisoBuscado = ref('')
    const menuBuscado = ref('')
    const permisoPadre = ref('')
    const menuPadreSeleccionado = ref()
    const permisoPadreSeleccionado = ref('')
    const listadoOrdenamientosMenusHijos = ref([])
    const creandoMenus = ref(false)
    const items = ref([
      {
        label: 'Permisos',
        to: { name: 'ajustes.permisos.listado-permisos' }
      },
      {
        label: 'Menus',
        to: { name: 'ajustes.permisos.listado-menus' }
      }
    ])
    const iconos = ref(['home', 'copy', 'book', 'chart-bar', 'cloud-upload', 'chart-bar', 'wifi', 'wallet', 'users', 'th-large', 'tag', 'table', 'sitemap', 'shopping-cart', 'shield', 'send', 'pencil', 'money-bill', 'map', 'list', 'key', 'info-circle', 'id-card', 'heart', 'file', 'eye', 'external-link', 'dollar', 'desktop', 'comments', 'cog', 'check', 'briefcase', 'bookmark'])
    const displayModalCrearPermiso = ref(false)
    const displayModalCrearMenu = ref(false)
    const dataPermiso = ref({
      servicio: false,
      action: '',
      creacion_edicion: false,
      description: '',
      editable: false,
      name: '',
      parent_permission_id: null
    })
    const dataMenu = ref({
      icon: '',
      name: '',
      action: '',
      parent_menu_id: null,
      order: 0,
      state: true,
      nivel: 0,
      servicio: false,
      ordenamiento_hermanos: []
    })
    // Métodos
    const setPermisoSeleccionado = () => {
      permisosStore.$patch((state) => {
        state.params.global = permisoBuscado.value.action ? permisoBuscado.value.action : permisoBuscado.value
      })
      permisosStore.paginarPermisos()
    }
    const setMenuSeleccionado = () => {
      menusStore.$patch((state) => {
        state.params.name = menuBuscado.value.action ? menuBuscado.value.action : menuBuscado.value
      })
      menusStore.paginarMenus()
    }
    const limpiarFiltro = () => {
      permisoBuscado.value = ''
      setPermisoSeleccionado()
      permisosStore.paginarPermisos()
    }
    const buscarPermisos = (search) => {
      if (search.length > 3) permisosStore.buscarPermisos(search)
    }
    const buscarMenus = (nombre) => {
      if (nombre.length > 3) menusStore.buscarMenus(nombre)
    }
    const crearPermiso = () => {
      if (!dataPermiso.value.name || !dataPermiso.value.description || !dataPermiso.value.action) {
        return Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Debes llenar todos los campos!'
        })
      } else {
        permisosStore.crearPermiso(dataPermiso.value).then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            text: 'Permiso creado con exito'
          }).then(() => {
            permisosStore.paginarPermisos()
          })
        })
        cerrarModalCrearPermiso()
      }
    }
    const setMenuPadre = () => {
      dataMenu.value.parent_menu_id = menuPadreSeleccionado.value.id
      dataMenu.value.action = menuPadreSeleccionado.value.action + '.'
      dataMenu.value.nivel = menuPadreSeleccionado.value.nivel + 1
      listadoOrdenamientosMenusHijos.value = menuPadreSeleccionado.value.menusHijos
      dataMenu.value.order = menuPadreSeleccionado.value.menusHijos.length + 1
      listadoOrdenamientosMenusHijos.value.push({
        icon: dataMenu.value.icon,
        name: dataMenu.value.name,
        order: menuPadreSeleccionado.value.menusHijos.length + 1,
        state: true,
        nuevo: true
      })
      permisoPadreSeleccionado.value = ''
      dataMenu.value.ordenamiento_hermanos = listadoOrdenamientosMenusHijos.value
    }
    const crearMenu = () => {
      creandoMenus.value = true
      for (const [key, value] of Object.entries(dataMenu.value)) {
        if (dataMenu.value.servicio) {
          if (!value && key !== 'parent_menu_id') {
            return Swal.fire(
              'Error',
              'Debes completar todos los campos',
              'error'
            )
          }
        } else {
          if (!value && key !== 'servicio') {
            return Swal.fire(
              'Error',
              'Debes completar todos los campos',
              'error'
            )
          }
        }
      }
      menusStore.crearMenus(dataMenu.value).then(() => {
        Swal.fire(
          'Guardado',
          'Menú creado con éxito',
          'success'
        ).then(() => {
          menusStore.paginarMenus()
          creandoMenus.value = false
        })
      })
      cerrarModalCrearMenu()
    }
    const cerrarModalCrearMenu = () => {
      displayModalCrearMenu.value = false
      dataMenu.value = {
        icon: '',
        name: '',
        action: '',
        parent_menu_id: null,
        order: null,
        state: true,
        nivel: 0,
        servicio: false,
        ordenamiento_hermanos: []
      }
      listadoOrdenamientosMenusHijos.value = []
      menuPadreSeleccionado.value = ''
    }
    const setMenuServicio = () => {
      dataMenu.value.parent_menu_id = null
      dataMenu.value.action = ''
      dataMenu.value.servicio ? listadoOrdenamientosMenusHijos.value = [...listadoTodosServicios.value] : listadoOrdenamientosMenusHijos.value = []
      if (listadoOrdenamientosMenusHijos.value.length) {
        listadoOrdenamientosMenusHijos.value.push({ name: dataMenu.value.name ? dataMenu.value.name : 'nuevo', order: listadoOrdenamientosMenusHijos.value.length + 1, nuevo: true })
        dataMenu.value.nivel = 1
        dataMenu.value.order = listadoOrdenamientosMenusHijos.value.length + 1
      }
      dataMenu.value.ordenamiento_hermanos = listadoOrdenamientosMenusHijos.value
    }
    const cerrarModalCrearPermiso = () => {
      displayModalCrearPermiso.value = false
      dataPermiso.value = {
        servicio: false,
        action: '',
        creacion_edicion: false,
        deletedAt: null,
        description: '',
        editable: false,
        name: '',
        parent_permission_id: null,
        service_id: null
      }
      permisoPadreSeleccionado.value = ''
    }
    const limpiarPadres = () => {
      dataPermiso.value.parent_permission_id = null
      dataPermiso.value.action = ''
    }
    const rowClass = (data) => {
      return data.nuevo ? 'fondo-amarillo' : ''
    }
    const onRowReorder = (event) => {
      event.value.map((value, key) => {
        value.order = key + 1
        if (value.nuevo) dataMenu.value.order = key + 1
      })
      listadoOrdenamientosMenusHijos.value = event.value
      dataMenu.value.ordenamiento_hermanos = event.value
    }
    const setListadoOrdenamiento = (name) => {
      if (dataMenu.value.servicio) {
        listadoOrdenamientosMenusHijos.value.map(a => {
          if (a.nuevo) a.name = name
        })
      }
    }
    const setPermisoPadreSeleccionado = () => {
      dataPermiso.value.parent_permission_id = permisoPadreSeleccionado.value.id
      dataPermiso.value.action = permisoPadreSeleccionado.value.action + '.'
    }
    onMounted(() => {
      serviciosStore.obtenerTodosServicios()
    })
    return {
      items,
      displayModalCrearPermiso,
      dataPermiso,
      permisoBuscado,
      menuBuscado,
      setPermisoSeleccionado,
      setMenuSeleccionado,
      buscarPermisos,
      listadoPermisos,
      limpiarFiltro,
      crearPermiso,
      cerrarModalCrearPermiso,
      permisoPadre,
      limpiarPadres,
      buscarMenus,
      listadoMenus,
      displayModalCrearMenu,
      iconos,
      crearMenu,
      cerrarModalCrearMenu,
      dataMenu,
      setMenuServicio,
      setMenuPadre,
      onRowReorder,
      listadoOrdenamientosMenusHijos,
      rowClass,
      menuPadreSeleccionado,
      creandoMenus,
      setListadoOrdenamiento,
      permisoPadreSeleccionado,
      setPermisoPadreSeleccionado
    }
  }
}
</script>
<style>
::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-input-placeholder { /* Edge */
  color: gray;
}
:global(.swal2-container) {z-index: 1000000 !important}
.fondo-amarillo {
  background-color: hsla(56, 100%, 50%, 0.993) !important;
}
</style>
